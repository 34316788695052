
          @import "@/assets/scss/_variables.scss";
        



















































@media (max-width: 768px) {
  div.find-clinic {
    // height: auto;
    margin-top: 70px;
    height: calc(100vh - 70px);
    overflow: auto;
    .page-inner {
      .page-title {
        margin-top: 0;
      }

      .select-sido {
        width: 100%;
      }

      .clinic-list {
        height: auto;

        .list-wrap {
          width: 100%;
          margin-right: 0;
          // height: calc(100vh - 390px);
          // max-height: 600px;
          // overflow: auto;
          .list {
            .list-item {
              position: relative;
              overflow: visible;

              &.active {
                margin-bottom: calc(90vw + 20px);

                .map-wrap {
                  height: 90vw;
                  padding: 10px;
                }
              }

              .map-wrap {
                position: absolute;
                display: block;
                width: 100%;
                height: 0;
                left: 0;
                top: 150px;
                transition: all 0.4s;
                padding: 0;

                .map {
                  overflow: hidden;
                  height: 100%;

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        .map-wrap {
          display: none;
        }
      }
    }
  }
}
.find-clinic {
  .bottom-wrap {
    .v-btn--block {
      display: flex;
      min-width: 100% !important;
      font-size: 20px;
      &:last-child {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
  // height: calc(100vh - 70px);
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .page-inner {
    .page-title {
      h1 {
        color: $primary-color;
        text-align: center;
      }
    }
    .sub-title {
      margin-top: 50px;
      text-align: center;
      span {
        display: inline-block;
        margin-top: 5px;
        font-size: 12px;
        line-height: 16px;
        color: $point-color;
        font-weight: 400;
      }
    }
    .section-inner {
    }
  }
  .select-sido {
    margin: 0 0 20px;
    width: 400px;
  }
  .result-num {
    margin: 0 0 20px;
  }
  .clinic-list {
    display: flex;
    width: 100%;
    height: 600px;

    .list-wrap {
      width: 400px;
      margin-right: 15px;
      overflow: auto;
      height: 100%;
      .list {
        .list-item {
          padding: 15px;
          background: #ffffff;
          margin-bottom: 10px;
          color: $primary-color;
          cursor: pointer;
          transition: all 0.4s;
          min-height: 58px;
          overflow: hidden;
          &.active {
            background: $primary-color;
            color: #ffffff;
            min-height: 140px;
            h3 {
              margin-bottom: 10px;
            }
            .detail {
              display: block;
              .v-icon {
                color: #ffffff;
              }
            }
          }

          .detail {
            display: none;
            padding-top: 10px;
            border-top: 1px solid #6a5767;
            p {
              margin-bottom: 5px;
            }
          }
          .map-wrap {
            display: none;
          }
        }
      }
    }
    .map-wrap {
      background: #ffffff;
      width: calc(100% - 415px);
      height: 100%;
      padding: 10px;
      .map {
        overflow: hidden;
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
