
          @import "@/assets/scss/_variables.scss";
        






















































































































.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
