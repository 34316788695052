
          @import "@/assets/scss/_variables.scss";
        



































































































































@media (max-width: 768px) {
  div.inquiry-detail {
    .detail-inner {
      margin: 40px auto 40px;
      padding: 15px;
      .detail-title {
        padding: 30px 10px;
        h2 {
          font-size: 20px;
        }
        .badge {
          padding: 1px 10px;
          margin-right: calc(100% - 60px);
          margin-bottom: 10px;
        }
        .inquiry-info {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 12px;
        }
      }
      .detail-content {
        padding: 30px 10px;
      }
      .adminCommentBox {
        flex-direction: column;
        .date {
          font-size: 12px;
          text-align: right;
        }
      }
      .reply-list {
        padding: 15px;

        .reply {
          // position: relative;
          // border-bottom: 1px solid $grey-color;
          padding: 10px 0;
          &:last-child {
            // border-bottom: none;
          }
          h4 {
            // margin-bottom: 10px;
          }

          .date {
            font-size: 12px;
          }

          &.mine {
            .btn-delete {
              // position: absolute;
              // top: 20px;
              // right: 0;
            }
            .admin {
              padding-left: 20px;
              .v-icon {
                margin-left: -24px;
              }
              > p {
                padding: 0;
              }
            }
          }
        }
      }
      .input-reply {
        padding: 15px;
      }
      .col.col-3 {
        padding: 12px 12px 12px 0;
        .v-btn {
          padding: 0 5px;
          font-size: 12px;
        }
      }
    }
    .bottom-button {
      text-align: center;
      margin: 40px auto 40px;
      .v-btn {
        width: 90vw;
        &:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.inquiry-detail {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding: 40px 0;
  .detail-inner {
    width: 90vw;
    max-width: 900px;
    margin: 100px auto 40px;
    background: #ffffff;
    padding: 20px 40px 60px;
    > .admin {
      background: #f4f4f4;
      padding: 20px;
    }
    .adminCommentBox {
      display: flex;
      padding: 10px;
      .left {
        margin-right: auto;
      }
    }
    .detail-title {
      position: relative;
      padding: 20px;
      border-bottom: 1px solid $point-color;
      .badge {
        display: inline-block;
        border: 1px solid $point-color;
        padding: 1px 15px;
        margin-right: 20px;
        color: $point-color;
        font-size: 12px;
      }
      .inquiry-info {
        position: absolute;
        right: 40px;
        top: 25px;
      }
    }
    .detail-content {
      padding: 40px 20px;
      margin-bottom: 40px;
      img {
        width: 100%;
      }
    }
    .reply-list {
      // margin-top: 40px 20px;
      background: #f4f4f4;
      padding: 20px;

      border-top: 1px solid $grey-color;
      .reply {
        position: relative;
        border-bottom: 1px solid $grey-color;
        padding: 30px 20px 20px;
        &:last-child {
          border-bottom: none;
        }
        h4 {
          margin-bottom: 10px;
        }
        .date {
          text-align: right;
          color: $secondary-color;
        }
        &.mine {
          .btn-delete {
            position: absolute;
            top: 20px;
            right: 0;
          }
        }
        .admin {
          > p {
            padding: 0 0 0 25px;
          }
        }
      }
    }
    .input-reply {
      // margin-top: 40px;
      background: #f4f4f4;
      padding: 40px 20px 15px;
    }
  }
  .bottom-button {
    text-align: center;
    margin: 40px 0;
    .v-btn {
      width: 300px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
