
          @import "@/assets/scss/_variables.scss";
        










































































































































.page.certify {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    // height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    .submit-btn-wrap {
      margin-top: 40px;
    }
  }
}
