
          @import "@/assets/scss/_variables.scss";
        











































































































































@media (max-width: 768px) {
  div .home {
    .page-section {
      height: auto;
      &.header {
        height: calc(100vh - 60px);
        margin-top: 60px;
      }
      .gradation {
        display: none;
      }
      // video {
      //   position: relative;
      //   object-fit: contain;
      // }
      .section-inner.full {
        background-image: url(~@/assets/images/home/video_bg.png);
        background-position: center;
        background-size: cover;
        height: 100%;

        .blur {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(5px);
          background-color: rgba(141, 141, 141, 0.1);
        }
      }
    }

    .page-section.fxl {
      background-image: url(~@/assets/images/home/mobile_home_thermage_bg_3.png);
      background-position: center;
      background-size: cover;
      height: calc(100vh - 60px);
      display: block;
      // padding: 40px 0 80px;
      .section-inner {
        padding-bottom: 20vh;
        text-align: center;
        .section-title {
          margin: 40px 0 30px;
          line-height: 0;
          text-align: center;
          img {
            width: 200px;
          }
        }
        .inner-text {
          padding-left: 0;
          margin: 1rem 0 9rem;
          font-size: 14px;
          color: $primary-color;
          font-weight: 600;
          b {
            color: $point-color;
            font-weight: 900;
          }
        }
        .inner-btn {
          .v-btn {
            height: 42px !important;
            font-size: 14px;
            border-width: 2px;
          }
        }
        .references {
          font-size: 12px;
          margin-top: 2rem;
        }
      }
    }
  }
}
.home {
  padding-bottom: 0;
  .page-section {
    height: calc(100vh - 70px);
    .gradation {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 10vh;
      background: linear-gradient(#ffffff, transparent);
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .page-section.fxl {
    display: flex;
    align-items: center;
    background-image: url(~@/assets/images/home/home_thermage_bg2.png);
    background-position: left center;
    background-size: cover;
    .gradation {
      width: 100%;
      height: 100%;
      opacity: 0.9;
      background: linear-gradient(90deg, transparent 20%, #ffffff);
    }
    .section-inner {
      position: relative;
      .section-title {
        font-size: 4rem;
        margin: 20px 0;
        img {
          width: 366px;
        }
      }
      .inner-text {
        padding-left: 60%;
        margin: 2rem 0 4rem;
      }
      .references {
        font-size: 14px;
        margin-top: 4rem;
        color: $secondary-color;
      }
    }
  }
}
