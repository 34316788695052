
          @import "@/assets/scss/_variables.scss";
        





































































































.my-page {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
}
