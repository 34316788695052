
          @import "@/assets/scss/_variables.scss";
        













































































































































.find-enter {
  .row {
    margin: 0;
    .col {
      padding: 0;
      .v-btn {
        min-width: calc(100% - 10px);
        padding: 0;
        margin-left: 10px;
      }
    }
  }
}
@media (max-width: 768px) {
  div.find-enter {
    .row-cell {
      .col-9 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .col-3 {
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
  }
}
