
          @import "@/assets/scss/_variables.scss";
        


















































































































































































































































.login {
  padding: 8rem 0 12rem;
  .section-inner {
    width: 65vw;
    max-width: 800px;
  }
  .login-header {
    padding: 0 1rem 2.5rem;
    text-align: center;
    img {
      width: 170px;
    }
    .input-guide {
      margin-top: 1.5rem;
      color: $primary-color;
    }
  }
  .v-btn.btn-kakao {
    background: #f6d523;
  }

  .link-group {
    margin-bottom: 1rem;
    text-align: right;
    .v-btn {
      text-decoration: underline;
      font-weight: 700;
    }
  }
}
@media (max-width: 768px) {
  div.login {
    padding: 4rem 0 2rem;
  }
}
