
          @import "@/assets/scss/_variables.scss";
        
























































































.v-dialog.common-dialog {
  .v-card {
    .v-data-table__mobile-row__header {
      min-width: 80px;
    }
    .v-card__actions {
      padding: 1rem 1rem 1.5rem;
    }
  }
}
