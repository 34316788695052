
          @import "@/assets/scss/_variables.scss";
        






























































































@media (max-width: 768px) {
  div.list-grid {
    .list-item {
      width: 100%;
      margin-right: 0;
    }
  }
}
.downloadBox {
  display: flex;
}
.list-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  .list-item {
    width: calc(25% - 18px);
    background: #fff;
    margin-right: 24px;
    margin-bottom: 24px;
    cursor: pointer;
    &:nth-child(4n + 0) {
      margin-right: 0;
    }
    &:hover {
      box-shadow: 0 0 10px 5px rgba(71, 71, 71, 0.2);
      transition: all 0.4s;
      // .thumbnail {
      //   background: #777574;
      //   transition: all 0.4s;
      //   .image {
      //     transition: all 0.4s;
      //     opacity: 0.8;
      //   }
      // }
    }
    .thumbnail {
      overflow: hidden;
      position: relative;
      padding-top: 100%;
      background: #dfdad6;
      .image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &.no-image {
          text-align: center;
          color: #a69e99;
          display: flex;
          align-content: center;
          justify-content: center;
          flex-direction: column;
          .v-icon {
            color: #a69e99;
          }
        }
      }
    }
    .text-wrap {
      padding: 15px 20px;
      h3 {
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-inline-box;
        -webkit-line-clamp: 1; /* 라인수 */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        // line-height: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        .badge {
          display: inline-block;
          border: 1px solid $point-color;
          padding: 1px 4px;
          margin-right: 5px;
          margin-bottom: 10px;
          font-size: 12px;
          color: $point-color;
        }
      }
      p {
        color: $secondary-color;
        font-size: 14px;
        span {
          display: inline-block;
          vertical-align: middle;
          &:first-child {
            &::after {
              content: "";
              display: inline-block;
              width: 1px;
              height: 10px;
              background-color: $secondary-color;
              margin-left: 10px;
            }
          }
          &:last-child {
            margin-left: 10px;
          }
        }
      }
      .btn-wrap {
        text-align: right;
        height: 36px;
      }
    }
  }
}
