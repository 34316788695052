
          @import "@/assets/scss/_variables.scss";
        













































































































































































@media (max-width: 768px) {
  div.top-nav {
    padding: 1rem;
    height: 100vh;
    left: 100vw;
    background: #ffffff;
    &:hover {
      height: 100vh;
    }
    &.on {
      left: 0;
      height: 100vh;
      .mobile-logo {
        display: none;
      }
      .mobile-btn {
        position: absolute;
        transition-delay: 1s;
      }
    }
    &.off {
      left: 100vw;
      // .mobile-btn {
      //   position: fixed;
      // }
    }
    &.bg {
      .mobile-top {
        background-color: #ffffff;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
      }
      .mobile-btn {
        .v-btn {
          // background-color: $chrome-background-color;
          // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
    .mobile-top {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 60px;
      justify-content: space-between;
      transition: all 0.4s;
      .mobile-logo {
        padding: 0.5rem 1rem;
        margin-top: -3px;
        cursor: pointer;
        img {
          width: 70px;
        }
      }
      .mobile-btn {
        padding: 0.5rem 1rem;
        .v-btn {
          transition: all 0.4s;
        }
      }
    }

    .nav-inner {
      flex-direction: column;
      height: calc(100vh - 40px);
      overflow: auto;
      .logo {
        margin-top: 0;
        padding: 0 0 1rem;
        img {
          width: 65px;
        }
      }
      .right {
        margin-top: 0;
        padding: 2rem 0 0;
        .v-divider {
          display: none;
        }
        img {
          width: 120px;
        }
      }
      .menu {
        flex-direction: column;
        padding: 1rem 0;
        border-bottom: 1px solid #ddd;
        width: 100%;
        // align-items: flex-start;
        .menu-item {
          position: relative;
          padding: 1rem;
          width: 100%;
          margin: 0;
          &.current {
            div {
              position: relative;
              display: inline-block;
              &::after {
                content: "";
                display: block;
                position: absolute;
                height: 2px;
                width: calc(100% + 20px);
                background: $point-color;
                margin-top: 3px;
                margin-left: -8px;
              }
            }
            position: relative;
            &::after {
              display: none;
            }
          }
        }
        .include-sub {
          display: block;
          width: 100%;
          // justify-content: flex-start;
          &.active {
            .btn-expend {
              transform: rotate(180deg);
            }
            .sub-list {
              display: block;
            }
          }
          .menu-item {
            width: calc(100% - 40px);
            .group-title {
              pointer-events: none;
            }
            .btn-expend {
              display: block;
              position: absolute;
              top: 10px;
              right: -40px;
            }
          }

          .sub-list {
            display: none;
            position: relative;
            text-align: left;
            margin: 15px 0 40px;
            // .sub-item {
            //   font-weight: 400;
            // }
          }
        }
      }
    }
  }
}
.top-nav {
  position: fixed;
  background: #ffffff;
  width: 100%;
  padding: 1rem 3rem;
  z-index: 100;
  transition: 0.6s;
  box-shadow: 0px 0 20px 0 #ffffff;
  height: 70px;
  overflow: hidden;
  &:hover {
    height: 240px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }
  &.bg {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }
  .mobile-top {
    display: none;
  }
  .nav-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo {
      margin-top: -10px;
      cursor: pointer;
      img {
        width: 85px;
      }
    }
    .right {
      margin-top: -10px;
      cursor: pointer;
      img {
        width: 160px;
      }
    }
    .menu {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: calc(100% - 265px);
      max-width: 1000px;
      .menu-item {
        margin: 0 1.5rem;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          color: $primary-color;
        }
        &.current {
          position: relative;
          &::after {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            width: calc(100% + 20px);
            background: $point-color;
            margin-top: 3px;
            margin-left: -8px;
          }
        }
      }
      #brand {
        .menu-item.current::after {
          margin-left: -11px;
        }
      }
      .include-sub {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .btn-expend {
          display: none;
        }
        .sub-list {
          position: absolute;
          margin: 30px 0 1rem;
          width: max-content;
          // left: -47%;
          text-align: center;
          .sub-item {
            padding: 0 1rem;

            cursor: pointer;
            margin: 1rem 0;
            &:hover {
              color: $primary-color;
            }
            &.current {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
