
          @import "@/assets/scss/_variables.scss";
        

















































































@media (max-width: 768px) {
  div.page.faq {
    .accordion {
      margin-bottom: 40px;
    }
  }
}
.page.faq {
  background: linear-gradient(#ffffff, #ebe5e1);
  .accordion {
    margin-bottom: 80px;
    .v-expansion-panels {
      border-radius: 0;
      row-gap: 1rem;
      text-align: left;
      .v-expansion-panel {
        border-radius: 0;
        border: 1px solid var(--gray-light-2);
        background: #f9f6f3;
        &::before {
          box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.05);
        }
        &::after {
          border: none;
        }
      }
      .v-expansion-panel-header {
        padding: 1.5rem;
        h4 {
          color: $primary-color;
          font-weight: bold;
          font-size: 18px;
          span.panel-title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-inline-box;
            -webkit-line-clamp: 1; /* 라인수 */
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            line-height: 1.4em;
            height: 1.4em;
            vertical-align: middle;
          }
        }
      }
      .v-expansion-panel-content {
        opacity: 0;
        transition: all 0.4s;
        padding: 0;
        border-radius: 0;
        .v-expansion-panel-content__wrap {
          border-top: 1px solid #d4d1cf;
          padding-top: 1rem;
          font-size: 0.9rem;
          span {
            display: inline-block;
            color: $point-color;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .v-expansion-panel--active {
        border-radius: 0;
        .v-expansion-panel-content {
          opacity: 1;
          padding: 0 1rem 1rem;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
