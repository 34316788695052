
          @import "@/assets/scss/_variables.scss";
        

























































































@media (max-width: 768px) {
  .v-dialog.fullscreen-dialog {
    .v-tabs {
      .v-slide-group__content {
        width: 100%;
      }
      .v-tab {
        width: 33%;
      }
    }
  }
}
.v-dialog.fullscreen-dialog {
  background: #ffffff;
  .v-tabs {
    position: relative;
    left: -1rem;
    width: 100vw;
    border-bottom: 1px solid var(--gray-light-2);

    .v-slide-group__prev {
      display: none !important;
    }
    .v-tab.v-tab--active {
      background: var(--gray-light-2);
    }
  }
  .v-tabs-items {
    margin-top: 1rem;
    font-size: 14px;
  }
}
