
          @import "@/assets/scss/_variables.scss";
        

























































































































































































@media (max-width: 768px) {
  div.inquiry-enter {
    .bottom-wrap {
      .v-btn--block {
        display: flex;
        min-width: 100% !important;
        &:last-child {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
}
.inquiry-enter {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .page-inner {
    width: 65vw;
    max-width: 800px;
  }
  .terms-box {
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding: 12px;
    margin-bottom: 12px;
    background: #ffffff;
    min-height: 56px;
    height: 160px;
    overflow: auto;
  }
  .bottom-wrap {
    margin: 5rem 0 1rem;
    .v-btn--block {
      display: inline-flex;
      min-width: initial !important;
      width: calc(50% - 6px);
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
