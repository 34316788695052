
          @import "@/assets/scss/_variables.scss";
        


















































































.page.tips {
  background: linear-gradient(#ffffff, #ebe5e1);
  .tip-list {
    margin-top: 150px;
    .item-box {
      display: flex;
      align-items: center;
      border: 1px solid var(--gray-light-2);
      background: #f9f6f3;
      padding: 40px 20px;
      box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.09);
      margin-bottom: 30px;
      .tip-img {
        width: 35%;
        padding: 20px;
        text-align: center;
        img {
          width: 250px;
        }
      }
      .tip-info {
        width: 65%;
        padding: 20px;
        .item-title {
          font-size: 35px;
          border-bottom: 1px solid $primary-color;
          padding-bottom: 15px;
          margin-bottom: 20px;
        }
        .info-item {
          display: flex;
          > div {
            margin: 15px 15px 15px 0;
            &:first-child {
              font-weight: bold;
              width: 80px;
            }
            &:last-child {
              &::before {
                content: "";
                display: inline-block;
                width: 1px;
                height: 20px;
                background: $chrome-color;
                margin-right: 20px;
                vertical-align: middle;
              }
            }
          }

          b {
            color: $point-color;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  div.page.tips {
    .tip-list {
      margin-top: 0;
      .item-box {
        flex-direction: column;
        .tip-img {
          width: 100%;
          img {
            width: 200px;
          }
        }
        .tip-info {
          width: 100%;
          .item-title {
            font-size: 25px;
          }
          .info-item {
            flex-direction: column;
            margin-bottom: 20px;
            > div {
              margin: 0;
              &:first-child {
                font-weight: bold;
                width: 80px;
              }
              &:last-child {
                &::before {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
