
          @import "@/assets/scss/_variables.scss";
        














































































































.v-dialog.fullscreen-dialog {
  background: #ffffff;

  #daumwrap {
    height: calc(100vh - 56px);
  }
  .search-complete {
    padding: 10px 20px;
  }
  table {
    width: 100%;
    border-top: 1px solid var(--gray-light-2);
    border-collapse: collapse;
    margin: 20px 0 40px;
  }

  th {
    width: 100px;
  }

  th,
  td {
    border-bottom: 1px solid var(--gray-light-2);
    border-left: 1px solid var(--gray-light-2);
    padding: 0.5rem;
  }

  th:first-child,
  td:first-child {
    border-left: none;
  }
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
