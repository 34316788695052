
          @import "@/assets/scss/_variables.scss";
        









































































































































































#file-input {
  display: none;
}
.profile-img {
  text-align: center;
  padding: 1rem 0 2rem;
  .img-wrap {
    position: relative;
    width: 5rem;
    height: 5rem;
    display: inline-block;
    .img-mask {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .v-btn {
      position: absolute;
      right: -7px;
      bottom: -5px;
      background: #ffffff;
    }
  }
}
