
          @import "@/assets/scss/_variables.scss";
        













































































































































































































































































































































































































































































































































.authenticated {
  background: linear-gradient(#ffffff, #ebe5e1);

  .page-section {
    text-align: center;
    // height: calc(100vh - 70px);
    // display: flex;
    // align-items: center;
  }

  .row {
    margin: 0;

    .col {
      padding: 0;

      .v-btn {
        min-width: calc(100% - 10px);
        padding: 0;
        margin-left: 10px;
      }
    }
  }

  .v-input--radio-group {
    margin: 40px 0 0;

    .v-radio {
      margin-right: 40px;
    }
  }

  .v-btn-toggle {
    width: 100%;
    margin-bottom: 30px;

    .v-btn {
      width: 50%;
    }
  }

  .terms-box {
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding: 12px;
    margin-bottom: 12px;
    background: #ffffff;
    min-height: 56px;
    height: 160px;
    overflow: auto;
  }

  .bottom-wrap {
    // position: absolute;
    // bottom: 1rem;
    // width: calc(100% - 2rem);
    margin: 1rem 0 1rem;

    .v-btn--block {
      display: inline-flex;
      min-width: initial !important;
      width: calc(50% - 6px);

      &:last-child {
        margin-left: 12px;
        margin-top: 0;
      }
    }
  }

  .v-input--checkbox {
    .v-label, .v-icon {
      font-size: 16px;
      font-family: "malgun gothic";
      margin-left: 30px;
    }
  }
}

@media (max-width: 768px) {
  div.authenticated {
    .v-input--radio-group {
      margin: 40px 0 0;

      .v-radio {
        margin: 0 0 10px;
      }
    }
  }
}
