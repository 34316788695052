
          @import "@/assets/scss/_variables.scss";
        
















































@media (max-width: 768px) {
  div.floating-buttons {
    width: 60px;
    height: auto;
    background: transparent;
    box-shadow: none;
    bottom: 0px;
    right: 10px;
    .v-btn {
      width: 60px;
      background: $point-color;
      margin-bottom: 10px;
      padding: 30px;
      border-radius: 30px;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
      .v-btn__content {
        display: flex;
        flex-direction: column;
        color: #fff;
        img {
          width: 30px;
          margin-top: -5px;
        }
        span {
          display: block;
          font-size: 10px;
          margin-top: 0;
        }
      }
    }
    .v-btn:nth-child(3),
    .v-btn:nth-child(4) {
      display: none;
    }
  }
}
.floating-buttons {
  position: fixed;
  z-index: 200;
  width: 80px;
  height: 350px;
  bottom: calc(5% + 210px);
  right: 0;
  background: $point-color;
  color: #fff;
  padding: 10px 0;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  .v-btn {
    width: 100%;
    text-align: center;
    padding: 40px;
    .v-btn__content {
      display: flex;
      flex-direction: column;
      color: #fff;
      img {
        width: 40px;
      }
      span {
        display: block;
        font-size: 12px;
        margin-top: 7px;
      }
    }
  }
}
