
          @import "@/assets/scss/_variables.scss";
        

























































































































@media (max-width: 768px) {
  div.page.inquiry {
    .list-table {
      .v-data-table {
        .v-data-table__wrapper > table {
          > tbody > tr {
            border-bottom: thin solid rgba(0, 0, 0, 0.12);
            > td {
              padding: 5px 0;
              min-height: 30px;
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                width: auto;
                padding-top: 20px;
                font-size: 18px;
                font-weight: 600;
              }
              &:nth-child(3) {
                width: auto;
                color: $secondary-color;
                font-size: 14px;
                padding-bottom: 20px;
                display: table-cell;
              }
              &:nth-child(4) {
                position: relative;
                width: auto;
                color: $secondary-color;
                font-size: 14px;
                padding-bottom: 20px;
                display: table-cell;
                border-bottom: none;

                &::before {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  top: 12px;
                  left: 0;
                  height: 10px;
                  width: 1px;
                  background-color: rgba(0, 0, 0, 0.12);
                }
              }

              &.v-data-table__mobile-row {
                .v-data-table__mobile-row__cell {
                  text-align: left;
                }
              }
              .v-data-table__mobile-row__header {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.page.inquiry {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .list-table {
    .v-data-table {
      background-color: transparent;
      border-radius: 0;
      .v-data-table__wrapper > table {
        > thead > tr > th {
          color: $primary-color;
        }
        > tbody > tr > td {
          padding: 25px 20px;
          font-size: 1rem;
          &:nth-child(1) {
            width: 80px;
          }
          &:nth-child(3) {
            width: 200px;
          }
          &:nth-child(4) {
            width: 150px;
          }
        }
        > thead > tr > th {
          padding: 25px 16px;
          font-size: 1rem;
        }
      }
    }
  }
  .bottom-button {
    text-align: center;
    margin: 40px 0;
    .v-btn {
      width: 300px;
    }
  }
}
