
          @import "@/assets/scss/_variables.scss";
        





















































































.v-footer {
  padding: 1.5rem 3rem;
  &.theme--dark {
    background-color: #000000;
  }
  .v-card {
    &.theme--dark {
      background-color: #000000;
    }
  }
  .v-card__title {
    padding: 0;
    align-items: flex-start;
    justify-content: space-between;
    p {
      font-size: 12px;
      line-height: 18px;
      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }
    .v-btn {
      margin-bottom: 1rem;
    }
  }
}
@media (max-width: 768px) {
  .v-footer {
    padding: 1.5rem 1.5rem;
    .footer-btns {
      width: 100%;
    }
  }
}
