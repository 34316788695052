
          @import "@/assets/scss/_variables.scss";
        














































































































































































@media (max-width: 768px) {
  div.page.notice {
    .list-table {
      .v-data-table {
        &.fixed-notice {
          border-bottom: 1px solid $point-color;
          .v-data-table__wrapper > table {
            > tbody > tr > td {
              &:nth-child(1) {
                display: flex;
                width: auto;
                padding-top: 15px;
              }
              &:last-child {
                padding-top: 0;
                padding-bottom: 20px;
                font-weight: bold;
              }
              .badge {
                width: 40px;
                padding: 1px;
                font-size: 12px;
              }
            }
          }
        }
        .v-data-table__wrapper > table {
          > tbody > tr {
            > td {
              padding: 5px 15px;
              min-height: 30px;
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                width: auto;
                padding-top: 20px;
              }
              &:nth-child(3) {
                width: auto;
                color: $secondary-color;
                font-size: 14px;
                padding-bottom: 20px;
              }
              &.v-data-table__mobile-row {
                // display: table-cell;
                .v-data-table__mobile-row__cell {
                  text-align: left;
                }
              }
              .v-data-table__mobile-row__header {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.page.notice {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding-bottom: 100px;
  .list-table {
    .v-data-table {
      background-color: transparent;
      border-radius: 0;
      &.fixed-notice {
        border-bottom: 1px solid $point-color;
      }
      .v-data-table__wrapper > table {
        > tbody {
          // > tr:not(:last-child) {
          //   > td {
          //     border-bottom: thin solid rgba(0, 0, 0, 0.12);
          //   }
          // }
          > tr > td {
            padding: 25px 20px;
            font-size: 1rem;
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(3) {
              width: 150px;
            }
            .badge {
              display: inline-block;
              border: 1px solid $point-color;
              padding: 1px 5px;
              text-align: center;
              color: $point-color;
            }
          }
        }

        > thead {
          display: none;
          > tr > th {
            padding: 25px 16px;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
