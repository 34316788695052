
          @import "@/assets/scss/_variables.scss";
        









































































































































.page.technology {
  padding: 2rem 0 0;

  .page-title {
    width: 90vw;
    margin: 70px auto 40px;
  }

  .page-section.step {
    background: linear-gradient(#ffffff, #ebe5e1);

    .step-list {

      // margin-top: 150px;
      .list-item {
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 40px;
        justify-content: space-between;

        .step-img {
          text-align: center;

          img {
            width: 250px;
          }
        }

        .step-info {
          width: 65%;
          padding: 20px;
          display: flex;
          align-items: center;

          .item-title {
            font-size: 60px;
            margin-right: 40px;
            color: #b0a8a2;

            &::after {
              content: "";
              display: inline-block;
              height: 60px;
              background: $chrome-color;
              margin-left: 40px;
              vertical-align: middle;
            }
          }

          p {
            margin: 0;

            b {
              color: $point-color;
            }
          }
        }
      }
    }
  }

  .page-section.approval {
    background: #ffffff;
    padding-bottom: 80px;

    .section-title {
      border-bottom: 1px solid $point-color;
      padding-bottom: 40px;
    }

    .approval-item {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--gray-light-2);
        background: #ffffff;
        width: 220px;
        height: 220px;
        box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.09);

        img {
          width: 140px;
          margin-left: 15px;
        }
      }

      .text-wrap {
        margin-left: 40px;
        width: calc(100% - 260px);

        .item-title {
          margin-bottom: 20px;
          font-size: 30px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  div.page.technology {
    .page-section.step {
      .step-list {
        .list-item {
          flex-direction: column;

          .step-img {
            img {
              width: 100%;
            }
          }

          .step-info {
            flex-direction: column;
            width: 100%;
            padding: 0;
            align-items: start;

            .item-title {
              font-size: 40px;
              margin-right: 0;

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }

    .page-section.approval {
      background: #ffffff;
      padding-bottom: 40px;

      .section-title {
        font-size: 20px;
      }

      .approval-item {
        flex-direction: column;

        &:last-child {
          margin-bottom: 0;
        }

        .icon-wrap {
          width: 170px;
          height: 170px;

          img {
            width: 100px;
          }
        }

        .text-wrap {
          margin-left: 0;
          margin-top: 20px;
          width: 100%;
          text-align: center;

          .item-title {
            margin-bottom: 20px;
            font-size: 30px;
          }
        }
      }
    }
  }
}