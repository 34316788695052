
          @import "@/assets/scss/_variables.scss";
        
























































@import "assets/scss/style";
// .v-btn {
//   margin-left: 5px;
// }
.intro {
  margin: 10px;
  font-size: 15px;
}
