
          @import "@/assets/scss/_variables.scss";
        























































.page.principle {
  background: linear-gradient(#ffffff, #ebe5e1);

  .principle-list {
    margin: 150px 0 30px;
    display: flex;

    .item-box {
      width: calc(50% - 15px);
      border: 1px solid var(--gray-light-2);
      background: #f9f6f3;
      padding: 40px 20px;
      box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.09);
      text-align: center;

      &:first-child {
        margin-right: 30px;
      }

      img.icon {
        width: 34px;
        margin-bottom: 15px;
      }

      .item-title {
        font-size: 35px;
        border-bottom: 1px solid $chrome-color;
        padding-bottom: 15px;
        margin-bottom: 30px;
      }

      p {
        line-height: 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  div.page.principle {
    .principle-list {
      margin: 0 0 30px;
      flex-direction: column;

      .item-box {
        width: 100%;

        &:first-child {
          margin-right: 0;
          margin-bottom: 30px;
        }

        img.icon {
          width: 24px;
          margin-bottom: 10px;
        }

        .item-title {
          font-size: 25px;
        }

        p {
          line-height: 24px;
        }
      }
    }
  }
}