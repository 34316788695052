
          @import "@/assets/scss/_variables.scss";
        

















































.old-policy {
	padding: 40px;
	// border: 10px solid #691c32;
	font-size: 14px;
	color: #333;
	background-color: #ffffff;
	position: relative;
	z-index: 2000;
	>div {
		margin: 80px auto;
		width: 90vw;
	}
}

.old-policy strong {
	font-size: 18px;
	// color: #691c32;
	font-weight: bold;
}


@media (max-width:780px) {
	.old-policy {
		margin: 40px 40px;
		padding: 20px;
		font-size: 13px;
	}

	.old-policy strong {
		font-size: 15px;
		// color: #691c32;
	}
}

@media (max-width:640px) {
	.old-policy {
		margin: 0 0;
		padding: 10px;
	}
}
