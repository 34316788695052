
          @import "@/assets/scss/_variables.scss";
        










































































































@media (max-width: 768px) {
  div.archive-detail {
    .detail-inner {
      margin: 40px auto 40px;
      padding: 15px;
      .detail-title {
        padding: 30px 10px;
        h2 {
          font-size: 20px;
        }
        .badge {
          padding: 1px 10px;
          margin-right: calc(100% - 60px);
          margin-bottom: 10px;
          font-size: 12px;
        }
      }
      .detail-content {
        padding: 30px 10px;
      }
    }
  }
}
.vodImgInfo {
  font-size: 12px;
}
.archive-detail {
  background: linear-gradient(#ffffff, #ebe5e1);
  padding: 40px 0;
  .detail-inner {
    width: 90vw;
    max-width: 900px;
    margin: 100px auto 40px;
    background: #ffffff;
    padding: 20px 40px;
    .detail-title {
      padding: 20px;
      border-bottom: 1px solid $point-color;
      h2 {
        color: $point-color;
      }
      div {
        margin: 20px 0 30px 0;
        img {
          float: left;
          width: 20px;
          height: 20px;
          filter: invert(31%) sepia(34%) saturate(2162%) hue-rotate(267deg) brightness(88%) contrast(88%);
          cursor: pointer;
        }
        span {
          float: right;
          display: inline-block;
          border: 1px solid $point-color;
          padding: 1px 4px;
          margin-right: 5px;
          margin-bottom: 10px;
          font-size: 12px;
          background-color: $point-color;
          color: #ffffff;
          border-radius: 10px;
        }
      }
      .badge {
        display: inline-block;
        border: 1px solid $point-color;
        padding: 1px 15px;
        margin-right: 20px;
        color: $point-color;
      }
    }
    .detail-content {
      padding: 40px 20px;
      img {
        width: 100%;
      }
    }
  }
  .bottom-button {
    text-align: center;
    margin: 40px 0;
    .v-btn {
      width: 300px;
    }
  }
  .video-wrap {
    position: relative;
    padding-top: 56%;
    width: 100%;
    height: 0;
    /* iframe */
    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: #292929;
    }
  }
}
