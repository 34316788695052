
          @import "@/assets/scss/_variables.scss";
        


























































































































































































































.user-profile {
  padding: 8rem 0 12rem;
  .section-inner {
    max-width: 800px;
    width: 65vw;
  }
  .section-header {
    padding: 0 1rem 2.5rem;
    text-align: center;
    .input-guide {
      margin-top: 1.5rem;
      color: $primary-color;
    }
  }
  .row {
    margin: 0;
    .col {
      padding: 0;
      .v-btn {
        min-width: calc(100% - 10px);
        padding: 0;
        margin-left: 10px;
      }
    }
  }
  .v-btn-toggle {
    width: 100%;
    margin-bottom: 30px;
    .v-btn {
      width: 50%;
    }
  }
  .bottom-wrap {
    margin: 5rem 0 1rem;
    .v-btn--block {
      display: inline-flex;
      min-width: initial !important;
      width: calc(50% - 6px);
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
@media (max-width: 768px) {
  div.user-profile {
    padding: 4rem 0 8rem;
    .row-cell {
      .col-9 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .col-3 {
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
    .row-code {
      flex-direction: column;
      margin-bottom: 24px;
      .col {
        max-width: 100%;
        .v-btn {
          min-width: 100%;
          margin-left: 0;
        }
      }
    }
    .bottom-wrap {
      .v-btn--block {
        display: flex;
        min-width: 100% !important;
        &:last-child {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
}
