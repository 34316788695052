
          @import "@/assets/scss/_variables.scss";
        


























.find-pw {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
  }
}
@media (max-width: 768px) {
  div.find-pw {
    .page-section {
      height: auto;
    }
  }
}
