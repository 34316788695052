
          @import "@/assets/scss/_variables.scss";
        









































.v-dialog.confirm-dialog {
  .v-card {
    .v-card__text {
      padding: 1.5rem 1rem 1rem;
      color: #000;
    }
    .v-card__actions {
      padding: 1rem 1rem 1.5rem;
      justify-content: flex-end;
    }
  }
}
