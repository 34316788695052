
          @import "@/assets/scss/_variables.scss";
        

























































































































































._gap {
  display: inline-block;
  width: 10px;
}
.text-delete {
  color: #ff000a;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
