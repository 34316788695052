
          @import "@/assets/scss/_variables.scss";
        















































.v-dialog.common-dialog {
  .v-card {
    .v-card__text {
      padding: 1.2rem 1rem 1rem;
    }
    .v-card__actions {
      padding: 1rem 1rem 1.5rem;
      justify-content: flex-end;
    }
  }
}
