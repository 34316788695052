
          @import "@/assets/scss/_variables.scss";
        
































































div.v-list:not(.v-select-list) {
  background: transparent !important;

  .v-list-item {
    padding: 0;

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__title {
      //font-size: 14px;
      //font-weight: bold;
      //padding: 0 15px 5px;
      //color: #108000;

      .asterisk {
        color: #de0043;
      }
    }

    &.email {
      .inner-content {
        display: flex;

        span {
          padding: 15px 6px;
          font-weight: 700;
        }
      }
    }

    .v-input {
      .v-input__slot {
        .v-btn {
          font-weight: 700;
          background-color: #43163e;
          color: #ffffff;
          font-size: 12px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }

    .v-input {
      & ~ .input-guide {
        display: block;
        font-size: 12px;
        padding: 0 10px 20px;
        margin-top: -5px;
      }

      &.error--text {
        & ~ .input-guide {
          display: none;
        }
      }
    }
  }
}
