
          @import "@/assets/scss/_variables.scss";
        





































































.newLabel {
  color: #40073c;
}
