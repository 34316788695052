
          @import "@/assets/scss/_variables.scss";
        






































































.page.brand {
  .page-section {
    &.header {
      background: linear-gradient(#ffffff, #ebe5e1);
      text-align: center;
      .section-inner {
        height: 100%;
        padding: 6rem 0 0;
      }
      .section-title {
        font-size: 60px;
        color: $primary-color;
        margin-bottom: 20px;
      }
      .inner-text {
        margin: 0 0 40px;
        font-weight: 600;
        b {
          color: $point-color;
          font-weight: 900;
        }
      }
      .main-img {
        width: 730px;
        margin-right: 50px;
      }
    }
    &.device {
      position: relative;
      height: calc(100vh - 70px);
      color: #ffffff;
      background: linear-gradient(-45deg, #1f0416, #4c2c42, #68465d);
      overflow: hidden;
      .section-inner {
        height: 100%;
        padding: 6rem 0 0;
      }
      .section-title {
        img {
          width: 470px;
        }
      }
      .inner-text {
        padding-left: 60%;
        margin: 2rem 0 8rem;
        font-size: 30px;
      }
      .device-img {
        position: absolute;
        width: 700px;
        left: 5vw;
        bottom: 0;
      }
    }
    &.feature {
      text-align: center;
      background: #ffffff;
      .section-inner {
        height: 100%;
        padding: 6rem 0;
      }
      .section-title {
        font-size: 60px;
        color: $primary-color;
        margin-bottom: 20px;
      }
      .inner-text {
        margin: 1rem 0 0;
        b {
          color: $point-color;
        }
      }
      .feature-list {
        margin: 8rem 0 0;
        .list-row {
          display: flex;
          justify-content: space-evenly;
          margin: 0 0 8rem;
        }
        .list-item {
          .icon {
            position: relative;
            .img-icon {
              width: 110px;
              border-radius: 50%;
              box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
            }
            .img-badge {
              position: absolute;
              left: 50%;
              top: -15px;
              height: 48px;
              &.circle {
                left: 60%;
              }
            }
          }
          p {
            margin-top: 15px;
            font-weight: bold;
            b {
              color: $point-color;
            }
          }
        }
      }
    }
  }
  // .reference {
  //   padding: 40px;
  //   font-size: 14px;
  //   color: $secondary-color;
  // }
}
@media (max-width: 768px) {
  div.page.brand {
    .page-section {
      &.header {
        .top-logo {
          display: none;
        }
        .section-title {
          font-size: 40px;
          margin: 20px 0;
        }
        .inner-text {
          margin: 0;
          word-break: keep-all;
        }
        .main-img {
          width: 100%;
          margin-top: 0;
          margin-left: -10px;
        }
      }
      &.device {
        height: calc(100vh - 70px);
        .section-inner {
          padding: 4rem 0 0;
          text-align: left;
        }
        .section-title {
          text-align: left;
          margin: 20px 0;
          img {
            width: 220px;
          }
        }
        .inner-text {
          padding-left: 0;
          margin: 1rem 0 6rem;
          font-size: 16px;
        }
        .device-img {
          width: 400px;
          left: auto;
          right: -70px;
        }
      }
      &.feature {
        text-align: center;
        .section-inner {
          padding: 2rem 0;
        }
        .section-title {
          .main-title {
            font-size: 40px;
          }
        }
        .inner-text {
          margin: 1rem 0 0;
        }
        .feature-list {
          margin: 4rem 0 0;
          .list-row {
            margin: 0 0 2rem;
          }
          .list-item {
            width: 33.3%;
            .icon {
              .img-icon {
                width: 68px;
                box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
              }
              .img-badge {
                top: -7px;
                height: 24px;
              }
            }
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
