$page-background-color: #fafafa;
$grey-background-color: #eaeaea;
$chrome-background-color: #f0ebe5;
$primary-color: #671f62;
$btn-primary-color: #43163e;
$secondary-color: #606060;
$point-color: #963b90;
$warning-color: #ffba00;
$chrome-color: #b8b2ae;
$primary-border-color: #d9d9d9;
$list-border-color: #e8e8e8;

$active-lighter-color: lighten($point-color, 50%);
$active-light-color: lighten($point-color, 20%);

$primary-color-brgtns1: #43163e;
$primary-color-brgtns2: #5b1e54;
$primary-color-brgtns3: #6b2362;
$primary-color-brgtns4: #78286d;
$primary-color-brgtns5: #8a2e7e;
$primary-color-brgtns6: #98338b;
$primary-color-brgtns7: #a73898;
$primary-color-brgtns8: #ba3fa9;
$primary-color-brgtns9: #cd47bb;
$primary-color-brgtns10: #e054ce;

$grey-color: #cccccc;
$light-grey-color: #f4f4f4;
