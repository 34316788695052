@charset "utf-8";
@import "@/assets/scss/_variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
/* Reset */
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
blockquote,
pre,
code,
address,
ul,
ol,
li,
nav,
section,
article,
header,
footer,
main,
aside,
dl,
dt,
dd,
table,
thead,
tbody,
tfoot,
label,
caption,
th,
td,
form,
fieldset,
legend,
hr,
input,
button,
textarea,
object,
figure,
figcaption {
  margin: 0;
  padding: 0;
}

body,
input,
select,
textarea,
button,
img,
fieldset {
  border: none;
}

ul,
ol,
li {
  list-style: none;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
section {
  box-sizing: border-box;
  padding: 1rem 1rem !important;
}

address,
cite,
code,
em,
i {
  font-style: normal;
  font-weight: normal;
}

label,
img,
input,
select,
textarea,
button,
a {
  vertical-align: middle;
}

ins,
a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

/* Normalize */
body {
  font-family: "Nanum Gothic", "Noto Sans KR", sans-serif;
  box-sizing: border-box;
}

@media (min-width: 769px) {
  body {
    min-width: 1200px;
    .top-nav {
      min-width: 1200px;
    }
    .page {
      .page-section {
        min-height: 700px;
      }
    }
  }
}
/* 윗첨자 크기 조정 */
sup {
  font-size: 0.6em;
}

select {
  appearance: none;
}

select::-ms-expand {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"],
input[type="text"],
input[type="password"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="date"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  outline: 0;
  // font-family: "Noto Sans KR";
}

textarea {
  resize: none;
}

/* Style */
:root {
  /* 사주 */
  --gray-light-1: #f8f8fa;
  --gray-light-2: #ebebeb;
  --black-light: #3f3e3f;
  --gray: #b1b1b1;
  --gray-dark: #878787;
  --orange: #c95e39;
  --beige: #f8f0d8;
}

.bg-white {
  background-color: white !important;
}

.color-orange {
  color: $primary-color !important;
}

html {
  font-size: 16px;
}

body {
  overflow-x: hidden;
  font-size: 16px;
  // font-family: "Noto Sans KR";
  // padding-bottom: 10rem;
  background-color: var(--gray-light-1);
  color: var(--black-light);
}
body .v-application {
  font-family: "Nanum Gothic", "Noto Sans KR", sans-serif;
  .primary--text {
    color: $primary-color !important;
    caret-color: $primary-color !important;
  }
  .primary {
    background-color: $btn-primary-color !important;
    border-color: $btn-primary-color !important;
  }

  .secondary--text {
    color: var(--black-light) !important;
    caret-color: var(--black-light) !important;
  }
  .secondary {
    background-color: var(--gray) !important;
    border-color: var(--gray) !important;
    &.v-btn {
      background-color: var(--gray-light-2) !important;
      border-color: var(--gray-light-2) !important;
      color: var(--black-light) !important;
      caret-color: var(--black-light) !important;
    }
  }
  .point--text {
    color: $point-color !important;
    caret-color: $point-color !important;
  }
}

a,
button {
  color: #666;
  border-radius: 0.5rem;
}

p,
li {
  word-break: keep-all;
}

.clear {
  clear: both;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}
.detail-page {
  position: relative;
  min-height: 100vh;
  padding: 1rem;
}
.detail-header {
  display: flex;
  background-color: white;
  justify-content: space-between;
  // padding: 0 1rem;
  position: relative;
  align-items: center;
  margin-bottom: 1.5rem;
  h4 {
    width: 100%;
    text-align: center;
    margin-left: -36px;
  }
}
div.v-toast__item {
  background-color: #a32f94;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.v-item-group.v-bottom-navigation .v-btn {
  min-width: initial;
}
.v-btn {
  border-radius: 0;
  font-weight: 700;
}
.v-btn:not(.v-btn--round).v-size--x-large {
  height: 57px !important;
}
.v-btn.v-btn--text {
  .v-btn__content {
    text-decoration: underline;
  }
}
.v-btn.v-btn--block {
  margin: 0;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: $chrome-color;
  color: #ffffff;
}

.bottom-wrap {
  margin: 5rem 0 1rem;
  .v-btn--block {
    display: inline-flex;
    min-width: initial !important;
    width: calc(50% - 6px);
    &:last-child {
      margin-left: 12px;
    }
  }
  &.single {
    .v-btn--block {
      display: flex;
      min-width: 100% !important;
      &:last-child {
        margin-left: 0;
      }
    }
  }
}

// textarea
.v-textarea.v-text-field--outlined {
  > .v-input__control > .v-input__slot {
    margin-bottom: 12px;
    fieldset {
      background-color: #ffffff;
      border-radius: 0;
      border: 1px solid #f7f3f1;
    }
  }
  &.error--text {
    .v-text-field__details {
      display: initial;
    }
  }
  .v-text-field__details {
    display: none;
  }
}
input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
textarea::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #000000;
}
.v-input .v-input__control .v-label {
  color: rgba(0, 0, 0, 0.3);
}

// input
.v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: #ffffff;
  border-radius: 0;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) {
  > .v-input__control {
    > .v-input__slot {
      box-shadow: none !important;
      border-radius: 0;
      margin-bottom: 12px;
      border: 1px solid #f7f3f1;
      height: 57px !important;
    }
  }
  &.error--text {
    .v-text-field__details {
      display: initial;
    }
  }
  .v-text-field__details {
    display: none;
  }
}
.card {
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-light-1);
  background: var(--white, #fff);
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}
.v-dialog {
  border-radius: 0 !important;
  .v-card {
    // border: 3px solid $primary-color;
    background-color: $chrome-background-color;
    border-radius: 0 !important;
    .v-card__title {
      text-align: center;
      padding: 1rem 1rem;
      justify-content: center;
    }
    .v-card__text {
      padding: 0 1rem 1rem;
    }
    .v-card__actions {
      justify-content: center;
    }
  }
}
.v-dialog.content-dialog {
  .v-card {
    .v-card__text {
      padding: 0 1rem 2rem;
    }
  }
}
.v-dialog.menu-dialog {
  .v-list-item {
    border-bottom: 1px solid var(--gray-light-2);
    &:last-child {
      border-bottom: 0;
    }
  }
}

.v-input--selection-controls__input:hover
  .v-input--selection-controls__ripple:before {
  background: transparent;
}

// switch 라벨 공통 스타일
.v-input--switch {
  .v-input__slot {
    flex-direction: row-reverse;
  }
  .v-label {
    font-size: 14px;
    line-height: 14px;
    margin-top: 3px;
  }
  .v-input--selection-controls__input {
    margin: 0 0 0 12px;
  }
}

//
.v-carousel {
  .v-carousel__controls {
    .v-btn {
      height: 1rem;
      width: 1rem;
      margin: 0 4px;
      .v-icon {
        height: 0.7rem;
        width: 0.7rem;
        font-size: 0.7rem !important;
      }
    }
  }
}

// pagination
.v-data-table + nav {
  margin-top: 40px;
}
.v-pagination {
  .v-pagination__navigation {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    &.v-pagination__navigation--disabled {
      display: none;
    }
  }
  .v-pagination__item {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    font-size: 20px;
    &.primary {
      background-color: transparent !important;
      font-weight: bold;
      color: initial !important;
    }
  }
}

.page {
  margin-top: 70px;
  padding: 0;
  min-height: calc(100vh - 279px);
  .page-inner {
    padding: 2rem 0;
    margin: 0 auto;
    width: 80vw;
    max-width: 1200px;
  }
  .page-title {
    margin: 70px 0;
    text-align: center;
    .main-title {
      font-size: 50px;
      margin-bottom: 20px;
      word-break: keep-all;
      line-height: 55px;
      font-family: "Arial", "Noto Sans KR", sans-serif;
    }
    .sub-title {
      font-weight: 600;
      b {
        color: $point-color;
        font-weight: 900;
      }
    }
  }
  .page-section {
    position: relative;
    // text-align: center;
    &.bg {
      background: $chrome-background-color;
    }
    // &.reference {
    //   min-height: auto;
    //   height: auto;
    // }
  }
  .section-inner {
    padding: 2rem 0;
    margin: 0 auto;
    width: 80vw;
    max-width: 1200px;
    &.full {
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 0;
    }
    &.right {
      text-align: right;
      .section-title {
        text-align: right;
      }
    }
    &.left {
      text-align: left;
    }
    .top-logo {
      width: 200px;
      margin: 0 0 30px 0;
    }
    .section-title {
      margin: 70px 0;
      text-align: center;
      .main-title {
        font-size: 50px;
        margin-bottom: 20px;
        font-family: "Arial", "Noto Sans KR", sans-serif;
      }
      .sub-title {
        font-weight: 600;
        b {
          color: $point-color;
          font-weight: 900;
        }
      }
    }
    .input-guide {
      text-align: left;
      &.point-color {
        color: $point-color;
      }
      &.input-top {
        margin: 0 0 10px;
      }
      &.input-bottom {
        margin: -5px 0 15px;
      }
    }
  }
}
.reference {
  // position: absolute;
  // width: 100%;
  // bottom: 0;
  height: auto;
  min-height: auto;
  padding: 0;
  font-size: 13px;
  color: $secondary-color;
  // background-color: $chrome-color;
  background-color: #d7d1cc;

  .section-inner {
    padding: 1rem 0 1rem;
    margin: 0 auto;
    width: 80vw;
    max-width: 1200px;
    font-size: 10px;
    p {
      margin-top: 0 !important;
      margin: 0;
      text-align: left;
    }
  }
}

@media (max-width: 768px) {
  div.page {
    margin-top: 0;
    padding: 0;
    min-height: initial;
    .page-inner {
      width: 90vw;
    }
    .page-title {
      margin: 70px 0 35px;
      text-align: left;
      .main-title {
        font-size: 32px;
        line-height: 36px;
        // margin-bottom: 20px;
      }
      .sub-title {
        font-weight: 600;
        b {
          color: $point-color;
          font-weight: 900;
        }
      }
    }
    > .page-section.bg {
      padding-top: 3rem;
    }
    div.section-inner {
      width: 90vw;
      .top-logo {
        width: 120px;
        // margin: 0 0 50px;
      }
      .section-title {
        margin: 70px 0 35px;
        .main-title {
          font-size: 32px;
          line-height: 36px;
        }
        // .sub-title {
        //   font-weight: 600;
        //   b {
        //     color: $point-color;
        //     font-weight: 900;
        //   }
        // }
      }
      .input-guide {
        font-size: 12px;
        color: $point-color;
      }
    }
    .reference {
      padding: 0;
      font-size: 10px;
    }
  }
  div.bottom-wrap {
    margin: 2rem 0 1rem;
    .v-btn--block {
      display: flex;
      min-width: 100% !important;
      &:last-child {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
}
