
          @import "@/assets/scss/_variables.scss";
        















































.submitted {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    // height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    .submit-btn-wrap {
      margin-top: 40px;
    }
    .section-inner .section-title {
      .main-title {
        font-size: 34px;
      }
    }
    @media (max-width: 768px) {
      div.section-inner .section-title {
        .main-title {
          font-size: 18px;
        }
      }
    }
  }
}
