
          @import "@/assets/scss/_variables.scss";
        








































.page.genuine-product {
  background: linear-gradient(#ffffff, #ebe5e1);

  .page-content {
    position: relative;
    padding-top: 56.3%;
    width: 100%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
